var maxDays = maxDays || 180;
var maxPerson = maxPerson || 10;
var nowTemp = new Date();
var now = new Date(
  nowTemp.getFullYear(),
  nowTemp.getMonth(),
  nowTemp.getDate(),
  0,
  0,
  0,
  0
);
var now_sumbox =
  nowTemp.getDate() +
  '/' +
  ('0' + (nowTemp.getMonth() + 1)).slice(-2) +
  '/' +
  nowTemp.getFullYear();
var nextTemp = new Date(now.getTime() + 86400000); //new Date(now.getTime()+86400000); +86400000 = 1 day
var stopYear = new Date(nowTemp.getFullYear() + 1, 11, 31, 0, 0, 0, 0); //set years + 1
var stopStart = new Date(now.getTime() + 86400000 * (180 - 1)); // set condition : can only purchase within 180 from transaction date
var stopEnd = new Date(now.getTime() + 86400000 * (maxDays - 1)); // set condition : maximum number of days
var stopYear3 = new Date(now.getTime() + 86400000 * 743); //set today + 744days
var now_next5 = new Date(
  nowTemp.getFullYear(),
  nowTemp.getMonth(),
  nowTemp.getDate() + 5,
  0,
  0,
  0,
  0
);

/*== Annual setting ==*/
var stopStart2 = new Date();
stopStart2.setDate(stopStart2.getDate() + 1);
stopStart2.setFullYear(stopStart2.getFullYear() + 1);
stopStart2.setDate(stopStart2.getDate() - 1);
var stop2 =
  ('0' + stopStart2.getDate()).slice(-2) +
  '/' +
  ('0' + (stopStart2.getMonth() + 1)).slice(-2) +
  '/' +
  stopStart2.getFullYear();

/*== Oversea setting ==*/
//var userIncepDate = '<?=array_key_exists('goOs',$data)?($data['goOs']):""?>';
var userIncepDate = '';
if (typeof userIncepDate !== 'undefined' && userIncepDate !== '') {
  var incepDateOS = userIncepDate.split('/');
  var use = new Date(
    incepDateOS[2] + '-' + incepDateOS[1] + '-' + incepDateOS[0]
  );
  stopYear3 = new Date(use.getTime() + 86400000 * 743);
}
var checkin, checkout, checkin2, checkin3, checkout3;
var allPromo = {};

var schengen_country = [
  'Austria',
  'Belgium',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
];

function dateDiff(checkinDate, checkoutDate) {
  return (checkoutDate - checkinDate) / (1000 * 60 * 60 * 24) + 1;
}
function ddmmyyyy(myday) {
  var today = new Date(myday);
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  var today = dd + '/' + mm + '/' + yyyy;
  return today;
}

/*
	@cond = variable name next to "input_"
	@value= value to be updated
*/

function update_var(cond, value) {
  if (cond == 'cDes') {
    if (value != 'Worldwide') {
      var a = schengen_country.indexOf(value);
      if (a != -1) {
        $('#' + cond).val(value + '/Schengen');
        $('#' + 'input_' + cond).html(' ' + value + '/Schengen');
      } else {
        $('#' + cond).val(value);
        $('#' + 'input_' + cond).html(' ' + value);
      }

      /*
			$.ajax ({
				url: "{{env('PROJECT_PATH', '/' )}}{{ $project }}/ajaxCall",
				data: { val : value }, //optional
				success: function(result){
					if(result == 1){
						$('#'+cond).val(value+"/Schengen");
						$('#'+'input_'+cond).html(" "+value+"/Schengen");
					}
					else{
						$('#'+cond).val(value);
						$('#'+'input_'+cond).html(" "+value);
					}
				}
			});
    */
    } else {
      $('#' + cond).val(value);
      $('#' + 'input_' + cond).html(' ' + value);
    }

    var value = $("label[for='" + value + "']").text();
    $('#' + cond).val(value);
    $('#' + 'input_' + cond).html(' ' + value);
  } else if (cond == 'ctatype') {
    // if(value == 'single'){ value = "SINGLE_TRIP";}
    // else if(value == 'overseas_study'){ value = "OVERSEA_TRIP";}
    // else{ value = "ANNUAL_TRIP";}

    var value = $("label[for='" + value + "']").text();
    $('#' + cond).val(value);
    $('#' + 'input_' + cond).html(' ' + value);
  } else if (cond == 'cDateSt' || cond == 'cDateSp') {
    setMaxDate(cond);
    $('#' + cond).val(value);
    $('#' + 'input_' + cond).html(' ' + value);
    //console.log(cond+ " "+ value);
  } else {
    $('#' + cond).val(value);
    $('#' + 'input_' + cond).html(' ' + value);
  }
}

jQuery(document).ready(function () {
  $('input:radio[name=traveltype], input:radio[name=destination]').on(
    'change',
    function () {
      setMaxDate();
    }
  );

  $('#spanMember').show();

  $.ajaxSetup({
    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
  });

  $('.close-reveal-modal').click(function () {
    $('.navigation').css('visibility', 'visible');
  });

  var cday, cperson, datesp, datest, des, cperson_adult, cperson_dependent;
  var type = $('input:radio[name=traveltype]:checked').val();

  var policytype = $('input:radio[name=radPolicyType]:checked').val();

  var edm = ''; // "{{ $edm }}";
  if (edm != '') {
    document.getElementById('promocode').value = edm;
  }
  var prmc = $('#promocode').val();
  if (prmc) {
    get_promo();
  }

  // var arr_country = document.getElementById('inputTo');
  // var num_person = document.getElementById('inputNum');
  var num_person_Adult = document.getElementById('N01');
  var num_person_Dependent;
  var num_person;
  // var num_person = num_person_Adult + num_person_Dependent;

  show_traveller(policytype); /* set default traveller */

  checkin = $('.date1')
    .datepicker({
      format: 'dd/mm/yyyy',
      setStartDate: now,
      endDate: stopStart,
    })
    .on('changeDate', function (ev) {
      // From date is after to date, set to date to same as from date.
      if (ev.date.valueOf() > checkout.getDate().valueOf()) {
        var newDate = new Date(ev.date);
        newDate.setDate(newDate.getDate());
        checkout.setDate(newDate);
        checkout.setStartDate(newDate);
        update_var('cDateSp', ddmmyyyy(ev.date));
      } else {
        // From date changed but still before or same as to date, update
        // min date for to date.
        var newDate = new Date(ev.date);
        checkout.setStartDate(newDate);
      }
      var diff = dateDiff(
        checkin.getDate().valueOf(),
        checkout.getDate().valueOf()
      );
      update_var('cDday', diff);
      update_var('cDateSt', ddmmyyyy(ev.date));
      checkin.hide();
      $('.date2')[0].focus();
    })
    .data('datepicker');

  checkout = $('.date2')
    .datepicker({
      format: 'dd/mm/yyyy',
      minDate: 0,
      setStartDate: now,
      endDate: stopEnd,
    })
    .on('changeDate', function (ev) {
      // To date is before from date, set from date to same as to date.
      if (ev.date.valueOf() < checkin.getDate().valueOf()) {
        var newDate = new Date(ev.date);
        newDate.setDate(newDate.getDate());
        update_var('cDateSt', ddmmyyyy(ev.date));
        checkin.setDate(newDate);
      }
      var diff = dateDiff(
        checkin.getDate().valueOf(),
        checkout.getDate().valueOf()
      );
      update_var('cDday', diff);
      update_var('cDateSp', ddmmyyyy(ev.date));
      checkout.hide();
    })
    .data('datepicker');

  // checkin2 = $('.dateyear').datepicker({
  //     format: 'dd/mm/yyyy',
  //     setStartDate: now,
  //     endDate: stopStart
  // }).on('changeDate', function(ev) {
  //     var _date = new Date(ev.date);

  //     var myDate = new Date(_date);
  //     myDate.setFullYear(myDate.getFullYear() + 1);
  //     myDate.setDate(myDate.getDate() - 1);

  //     var newDate = ("0" + myDate.getDate()).slice(-2) + '/' + ("0" + (myDate.getMonth() + 1)).slice(-2) + '/' +  myDate.getFullYear();
  //     $('#backYr').val(newDate);
  //     update_var('cDateSt',ddmmyyyy(ev.date));
  //     update_var('cDateSp',newDate);
  //     checkin2.hide();
  //     $('#backYr')[0].focus();
  // }).data('datepicker');

  // checkin3 = $('.dateoversea').datepicker({
  //     format: 'dd/mm/yyyy',
  //     setStartDate: now,
  //     endDate: stopStart
  // }).on('changeDate', function(ev) {
  //     if (ev.date.valueOf() > checkout3.getDate().valueOf()) {
  //         var newDate = new Date(ev.date);
  //         newDate.setDate(newDate.getDate());
  //         checkout3.setDate(newDate);
  //         checkout3.setStartDate(newDate);
  //         checkout3.setEndDate(new Date(new Date(newDate).getTime()+(86400000*743)));
  // //            checkout3.setValue(newDate);
  //         update_var('cDateSp',ddmmyyyy(ev.date));
  //     }
  //     else{
  //         var newDate = new Date(ev.date);
  //         checkout3.setDate(newDate);
  //         checkout3.setStartDate(newDate);
  //         checkout3.setEndDate(new Date(new Date(newDate).getTime()+(86400000*743)));
  // //            checkout3.setValue(newDate);
  //         update_var('cDateSp',ddmmyyyy(ev.date));
  //     }
  //     var diff = dateDiff(checkin3.getDate().valueOf(),checkout3.getDate().valueOf());
  //     update_var('cDday',diff);
  //     update_var('cDateSt',ddmmyyyy(ev.date));
  //     checkin3.hide();
  //     $('.dateoversea2')[0].focus();
  // }).data('datepicker');

  // checkout3 = $('.dateoversea2').datepicker({
  //     format: 'dd/mm/yyyy',
  //     setStartDate: now,
  //     endDate: stopYear3
  // })
  // .on('changeDate', function(ev) {
  //     if (ev.date.valueOf() < checkin3.getDate().valueOf()) {
  //         var newDate = new Date(ev.date);
  //         newDate.setDate(newDate.getDate());
  //         update_var('cDateSt',ddmmyyyy(ev.date));
  // //            checkin3.setValue(newDate);
  //     }
  //     var diff = dateDiff(checkin3.getDate().valueOf(),checkout3.getDate().valueOf());
  //     update_var('cDday',diff);
  //     update_var('cDateSp',ddmmyyyy(ev.date));
  //     checkout3.hide();
  // }).data('datepicker');

  //============================================================================
  //===== Initial side box customer's travel infomation from input fields ======
  //============================================================================

  if ($('.date1').datepicker('getDate') == null) {
    $('.date1').datepicker('update', now);
    $('.date1').datepicker('setStartDate', now);
  } else {
    //$('.date1').datepicker('setStartDate', checkin.getDate());
    $('.date1').datepicker('setStartDate', now);
  }

  if ($('.date2').datepicker('getDate') == null) {
    $('.date2').datepicker('update', now);
    $('.date2').datepicker('setStartDate', now);
  } else {
    // $('.date2').datepicker('setStartDate', checkout.getDate());
    $('.date2').datepicker('setStartDate', now);
  }

  // if( $('.dateyear').datepicker('getDate') == null ){
  //     $('.dateyear').datepicker('update', now);
  //     $('.dateyear').datepicker('setStartDate', now);
  // }else{
  //     $('.dateyear').datepicker('setStartDate', checkin2.getDate());
  // }

  // if( $('.dateoversea').datepicker('getDate') == null ){
  //     $('.dateoversea').datepicker('update', now);
  //     $('.dateoversea').datepicker('setStartDate', now);
  // }else{
  //     $('.dateoversea').datepicker('setStartDate', checkin3.getDate());
  // }

  // if( $('.dateoversea2').datepicker('getDate') == null ){
  //     $('.dateoversea2').datepicker('update', now);
  //     $('.dateoversea2').datepicker('setStartDate', now);
  // }else{
  //     $('.dateoversea2').datepicker('setStartDate', checkout3.getDate());
  // }

  if (!$('#backYr').val()) {
    $('#backYr').val(stop2);
  }

  $('#N01').change(function () {
    var value = $(this).val();
    var maxN2 = maxPerson - value;
    $('#N02').attr({
      max: maxN2 < 0 ? 0 : maxN2,
    });
    update_var('cPersonAdult', value);
  });

  $('#N02').change(function () {
    var value = $(this).val();
    var maxN1 = maxPerson - value;
    $('#N01').attr({
      max: maxN1 < 0 ? 1 : maxN1,
    });
    update_var('cPersonDependent', value);
  });

  if (type == 'single' || type == 'single_individual_benefit') {
    checkin_val = checkin.getDate();
    checkout_val = checkout.getDate();
    if (checkin_val && checkout_val) {
      cday = dateDiff(checkin_val.valueOf(), checkout_val.valueOf());
    } else {
      cday = 0;
    }
    update_var('cDday', cday);
    update_var('cPersonAdult', $('#N01').val());
  }

  function get_value(type_val) {
    if (typeof type_val !== 'undefined') {
      if (type_val == 'annual') {
        datest = $('#goYr').val();
        datesp = $('#backYr').val();
        cday = 1;

        cperson_adult = $('#N01').val();
        cperson_dependent = $('#N02').val();
      } else if (type_val == 'overseas_study') {
        datest = $('#goOs').val();
        datesp = $('#backOs').val();

        checkin3_val = checkin3.getDate();
        checkout3_val = checkout3.getDate();
        if (checkin3_val && checkout3_val) {
          cday = dateDiff(checkin3_val.valueOf(), checkout3_val.valueOf());
        } else {
          cday = 0;
        }

        cperson_adult = $('#N01').val();
        cperson_dependent = $('#N02').val();
      } else if (
        type_val == 'single' ||
        type_val == 'single_individual_benefit'
      ) {
        datest = $('#go').val();
        datesp = $('#back').val();

        checkin_val = checkin.getDate();
        checkout_val = checkout.getDate();
        if (checkin_val && checkout_val) {
          cday = dateDiff(checkin_val.valueOf(), checkout_val.valueOf());
        } else {
          cday = 0;
        }

        cperson_adult = $('#N01').val();
        cperson_dependent = $('#N02').val();
      } else if (type_val == 'business') {
        datest = $('#go').val();
        datesp = $('#back').val();

        checkin_val = checkin.getDate();
        checkout_val = checkout.getDate();
        if (checkin_val && checkout_val) {
          cday = dateDiff(checkin_val.valueOf(), checkout_val.valueOf());
        } else {
          cday = 0;
        }

        cperson_adult = $('#N01').val();
        cperson_dependent = $('#N02').val();
      }

      des = $('input:radio[name=destination]:checked').val();
    }
  }

  function set_summarybox(type_val) {
    if (typeof type_val !== 'undefined') {
      get_value(type_val);
      //console.log("set_summarybox : "+type_val+" "+des+" "+datest+" "+datesp+" "+cday+" "+cperson);
      update_var('ctatype', type_val); //TRAVEL_TYPE id="input_ctatype"
      update_var('cDes', des); // ARRIVAL_COUNTRY id="input_cDes"
      update_var('cDateSt', datest); //DEPARTURE_DATE id="input_cDateSt"
      update_var('cDateSp', datesp); //ARRIVAL_DATE id="input_cDateSp"
      update_var('cDday', cday); //NO_OF_DAY id="input_cDday"
      update_var('cPerson', cperson); //NO_OF_PERSON 	id="input_cPerson"

      update_var(
        'cPersonAdult',
        cperson_adult ? cperson_adult : $('#N01').val()
      );
      update_var(
        'cPersonDependent',
        cperson_dependent ? cperson_dependent : $('#N02').val()
      );
    }
  }

  function showtype(type) {
    if (typeof type !== 'undefined') {
      // console.log(type);
      if (type == 'annual') {
        $('#annualTrip').css('display', 'block');
        $('#singleTrip').css('display', 'none');
        $('#overseaTrip').css('display', 'none');
        $('#singleonly').css('display', 'none');
        $('#notannual').css('display', 'none');
        $('label.st font').replaceWith(
          "<font>{!! trans('messages.DEPARTURE_DATE2') !!}</font>"
        );
        $('label.sp font').replaceWith(
          "<font>{!! trans('messages.ARRIVAL_DATE2') !!}</font>"
        );
      } else if (type == 'overseas_study') {
        $('#overseaTrip').css('display', 'block');
        $('#singleTrip').css('display', 'none');
        $('#annualTrip').css('display', 'none');
        $('#singleonly').css('display', 'block');
        $('#notannual').css('display', 'block');
        $('label.st font').replaceWith(
          "<font>{!! trans('messages.DEPARTURE_DATE2') !!}</font>"
        );
        $('label.sp font').replaceWith(
          "<font>{!! trans('messages.ARRIVAL_DATE2') !!}</font>"
        );
      } else {
        $('#singleTrip').css('display', 'block');
        $('#annualTrip').css('display', 'none');
        $('#overseaTrip').css('display', 'none');
        $('#singleonly').css('display', 'block');
        $('#notannual').css('display', 'block');
        $('label.st font').replaceWith(
          "<font>{!! trans('messages.DEPARTURE_DATE') !!}</font>"
        );
        $('label.sp font').replaceWith(
          "<font>{!! trans('messages.ARRIVAL_DATE') !!}</font>"
        );
      }
      set_summarybox(type);
    }
  }

  function beforeCheckPromo() {
    $('#sbtbutton').attr('disabled', true);
    $('#sbtbutton').removeClass('btn-danger');
  }

  function get_promo() {
    prmc = $('#promocode').val();

    usePromo = prmc.toUpperCase();
    if (prmc.length >= 1 && prmc.length <= 30) {
      var _result = '';
      if (jQuery.isEmptyObject(allPromo)) {
        $.ajax({
          method: 'POST',
          url: PROJECT_PATH + project + '/promotion_retrieval',
          data: 'promocode=' + md5(prmc),
        }).done(function (msg) {
          var obj = jQuery.parseJSON(msg);
          var promo = '';

          allPromo = obj;
          check_promotion(allPromo);
        });
      } else {
        check_promotion(allPromo);
      }
    } else {
      $('#sbtbutton').addClass('btn-danger');
      $('#status').html('<div></div>');
      $('#sbtbutton').attr('disabled', false);

      //	document.getElementById("promo_chk").value = "";
      //	document.getElementById("protype").value = "";
      //	document.getElementById("promo_id").value = "";
    }
  }

  function check_promotion(promo_data) {
    type = $('input:radio[name=traveltype]:checked').val();
    get_value(type);

    prmc = $('#promocode').val();
    usePromo = md5(prmc.toUpperCase());
    var promoChunk = '';
    var flag_promo = 0;
    var flag_condt = 0;
    var k = '';
    //var warn = '{!! trans('messages.WRONG_PROMO') !!}';
    var warn = PROMOCODE_INCORRECT; //'Promo Code is incorrect.<br>Please enter the promo code again or cancel the promo code.';

    var indexArr = '';
    var keyArr = '';

    var protype = '';
    var warn_th = '';
    var warn_en = '';

    for (k in promo_data) {
      if (promo_data.hasOwnProperty(k)) {
        if (k == usePromo) {
          promoChunk = promo_data[k];
          flag_promo = 1;
          break;
        } else {
          flag_promo = 0;

          $('#sbtbutton').attr('disabled', true);
        }
      }
    }
    if (flag_promo != 0) {
      /*===================== Format date input form was changed to fit with DB date format ======================*/
      var formatst = datest.split('/');
      var format_datest = formatst[2] + '-' + formatst[1] + '-' + formatst[0];

      var formatsp = datesp.split('/');
      var format_datesp = formatsp[2] + '-' + formatsp[1] + '-' + formatsp[0];

      for (keyArr in promoChunk) {
        for (indexArr in promoChunk[keyArr]) {
          // if condition not match only if one then break and look for new condition row

          if (promoChunk[keyArr].hasOwnProperty(indexArr)) {
            /* ===================== */

            if (type == 'overseas_study') {
              if (indexArr == 'traveltype') {
                promoChunk[keyArr][indexArr] == type ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'goOs') {
                promoChunk[keyArr][indexArr] > format_datest ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'backOs') {
                promoChunk[keyArr][indexArr] == format_datesp ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else {
                flag_condt = 1;
              }
            } else if (type == 'annual') {
              /* ===================== */
              //console.log('annual');

              if (indexArr == 'traveltype') {
                promoChunk[keyArr][indexArr] == type ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'goYr') {
                //alert(promoChunk[keyArr][indexArr]+" > "+format_datest);
                promoChunk[keyArr][indexArr] > format_datest ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'backYr') {
                promoChunk[keyArr][indexArr] == format_datesp ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else {
                flag_condt = 1;
              }
            } else if (
              type == 'single' ||
              type == 'single_individual_benefit'
            ) {
              if (indexArr == 'traveltype') {
                promoChunk[keyArr][indexArr] == type ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'inputTo') {
                promoChunk[keyArr][indexArr] == des ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'go') {
                promoChunk[keyArr][indexArr] > format_datest ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'back') {
                promoChunk[keyArr][indexArr] == format_datesp ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'cDday') {
                promoChunk[keyArr][indexArr] <= cday ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else if (indexArr == 'cPerson') {
                promoChunk[keyArr][indexArr] == cperson ||
                notset(promoChunk[keyArr][indexArr])
                  ? (flag_condt = 1)
                  : (flag_condt = 0);
              } else {
                flag_condt = 1;
              }
              flag_condt = 1;
            }
          }
          if (flag_condt == 0) break;
        }
        /*===================================================================================================================================*/
        if (flag_condt) {
          protype = promoChunk[keyArr]['protype'];
          promo_id = promoChunk[keyArr]['promoid'];
          warn_th = promoChunk[keyArr]['warn_th'];
          warn_en = promoChunk[keyArr]['warn_en'];

          $('#status').html(
            '<div class="msgPro"><i class="fas fa-check-circle fa-lg text-msig-blue"></i></div>'
          );

          $('#sbtbutton').attr('disabled', false);

          document.getElementById('promo_chk').value = 'OK';
          document.getElementById('protype').value = protype;
          document.getElementById('promo_id').value = promo_id;

          return true;
        }
      }

      $('#status').html(
        "<div class='text-msig-red'><i class='fas fa-times-circle fa-lg'>&nbsp;</i>" +
          warn +
          '</div>'
      );
      $('#sbtbutton').removeClass('btn-danger');
      $('#sbtbutton').attr('disabled', true);

      document.getElementById('promo_chk').value = 'NO';
      document.getElementById('protype').value = '';
      document.getElementById('promo_id').value = '';

      return false;
    } else {
      $('#status').html(
        "<div class='text-msig-red'><i class='fas fa-times-circle fa-lg'>&nbsp;</i>" +
          warn +
          '</div>'
      );
      $('#sbtbutton').removeClass('btn-danger');
      $('#sbtbutton').attr('disabled', true);

      document.getElementById('promo_chk').value = 'NO';
      document.getElementById('protype').value = '';
      document.getElementById('promo_id').value = '';

      return false;
    }
  }

  function notset(indexArr_val) {
    return (
      indexArr_val === null ||
      indexArr_val == '' ||
      indexArr_val == '0000-00-00' ||
      indexArr_val == 0
    );
  }

  function clearPromo() {
    var prmc = document.getElementById('promocode').value;

    $('#promocode').removeClass('object_ok'); // if necessary
    $('#promocode').removeClass('object_error');
    if (prmc.length >= 1) {
      document.getElementById('promo_chk').value = '';
      document.getElementById('protype').value = '';
      document.getElementById('promo_id').value = '';

      $('#status').html(
        '<div class="msgPro"><img src="images/warning.png"><label style="color:orange">&nbsp;&nbsp;<?php echo trans("messages.PRO_WARNING"); ?></label></div>'
      );
    } else {
      document.getElementById('promo_chk').value = '';
      document.getElementById('protype').value = '';
      document.getElementById('promo_id').value = '';

      $('#status').html('<div></div>');
    }
  }

  /*=========================== On document ready show travel type that user choses =============================*/
  showtype(type);

  /*=========================== Verify promotion code when condition was changed =============================*/
  //@if ($project_config['promo']  === "1")

  if (prmc && document.getElementById('promo_chk').value != '') {
    get_promo();
  }

  $('input[name=traveltype]:radio').change(function () {
    get_promo();
    showtype($(this).val());
  });

  $('input[name=destination]:radio').change(function () {
    update_var('cDes', $(this).val());
  });

  $('input[name=radPolicyType]:radio').change(function () {
    show_traveller($(this).val());
    // console.log('change policy ' + policytype);
  });

  function show_traveller(policytype) {
    var policytype_value = document.getElementById('policytype').value;
    var cPersonAdult_value = document.getElementById('cPersonAdult').value;
    var cPersonDependent_value =
      document.getElementById('cPersonDependent').value;

    if (policytype == 'individual') {
      /**
       * - “Adult(s)” should be set to 1 and made read-only and hidden.
       * - “Dependents(s)” should be set to 0 and made read-only and hidden.
       */
      $('#N01').val(1); /* adult = 1 */
      $('#N02').val(0); /* dependent = 0 */
      $('.divNumOfTraveller').hide();
      $('.divN01').hide();
      $('.divN02').hide();
    } else if (policytype == 'family') {
      /**
       * - “Adult(s)” should be defaulted to 2 and editable.
       * - “Dependent(s)” should be defaulted to 0 and editable.
       */
      $('.divNumOfTraveller').show();
      $('#N01').val(2); /* adult = 2 */
      $('#N02').val(0); /* dependent = 3 */

      $('#N01').attr({
        max: 2,
        min: 1,
      });
      $('#N02').attr({
        max: 3,
        min: 0,
      });
      $('.divN01').show();
      $('.divN02').show();
    } else if (policytype == 'group') {
      /**
       * - “Adult(s)” should be defaulted to 1 and editable.
       * - “Dependent(s)” should be defaulted to 0 and editable.
       */
      $('.divNumOfTraveller').show();
      var minAdult = 1;
      var maxAdult = maxPerson;
      var minDependent = 0;
      var maxDependent = maxPerson - minAdult;
      $('#N01').val(minAdult); /* adult = 1 */
      $('#N02').val(minDependent); /* dependent = 0 */

      $('#N01').attr({
        max: maxAdult,
        min: minAdult,
      });
      $('#N02').attr({
        max: maxDependent,
        min: minDependent,
      });
      $('.divN01').show();
      $('.divN02').show();
    }
    if (cPersonAdult_value !== '' && cPersonDependent_value !== '') {
      if (policytype !== 'individual') {
        if (policytype_value == policytype) {
          $('#N01').val(cPersonAdult_value);
          $('#N02').val(cPersonDependent_value);
        }
      }
    }

    update_var('cPersonAdult', $('#N01').val());
    update_var('cPersonDependent', $('#N02').val());
    // console.log('N01 ' +  $("#N01").val() + ' N02 ' + $("#N02").val());

    // update_var('cPersonAdult',num_person_Adult.options[num_person_Adult.selectedIndex].value);
    // update_var('cPersonDependent',num_person_Dependent.options[num_person_Dependent.selectedIndex].value);
  }

  // $("#inputTo").change(function(){get_promo();});
  $('#go').change(function () {
    get_promo();
  });
  $('#back').change(function () {
    get_promo();
  });
  $('#goYr').change(function () {
    get_promo();
  });
  $('#backYr').change(function () {
    get_promo();
  });
  $('#inputNum').change(function () {
    get_promo();
  });
  $('#proBnt').click(function () {
    get_promo();
  });

  $('#promocode').bind('input', function () {
    get_promo();
  }); /* fix copy & pase */

  $('#promocode').keyup(function () {
    get_promo();
  });
  $('#promocode').keydown(function () {
    beforeCheckPromo();
  });

  // @else
  /**
   *	$("input[name=traveltype]:radio").change(function() {
   *		showtype($(this).val());
   *	});
   */
  // @endif

  $('input[type="number"]').on('keyup change', function () {
    v = parseInt($(this).val());
    min = parseInt($(this).attr('min'));
    max = parseInt($(this).attr('max'));
    msg = NUM_TRAVELLER_BETWEEN + min + TO + max; //'Number of traveller(s) between '+ min + ' to ' + max;

    if (v < min) {
      $('#spanErrNumtraveller').html(msg);
      $(this).val(min);
    } else if (v > max) {
      $('#spanErrNumtraveller').html(msg);
      $(this).val(max);
    } /*else if(isNaN(v)){
              $(this).val(min);
        }*/ else {
      $('#spanErrNumtraveller').html('');
    }

    var arrChkTraveller = checkSumTraveller($(this).attr('id'));
    if (arrChkTraveller[0] == false) {
      $('#spanErrNumtraveller').html(arrChkTraveller[1]);
    }
  });
});

//============================================================================
//======================== Do Client validate form ===========================
//============================================================================
function dowhensubmit() {
  // iftypechange();
  // return chkAmtDay();
}
function clear_irrel(type) {
  if (type == 'annual' || type == 'overseas_study') {
    $('#inputTo').val('');
    $('#go').val('');
    $('#back').val('');
    $('#inputNum').val(1);
    $('#cPerson').val(1);

    $('#cPersonAdult').val(1);
    $('#cPersonDependent').val(1);
  } else if (type == 'single' || type == 'single_individual_benefit') {
    $('#inputToYr').val('');
    $('#goYr').val('');
    $('#backYr').val('');
  }
}
/*
	function iftypechange(){
		var type     = $('input:radio[name=traveltype]:checked').val();
		var type_old = "<?echo $data['traveltype']?>";

		clear_irrel(type);
		if(type_old != ''){
			if(type !== type_old){
				if(type == "annual"){
					$("#inputNum").val(1);
					$("#cPerson").val(1);
				}
			}
		}
	}
*/
function chkAmtDay() {
  var amt = document.getElementById('cDday').value;
  var type = $('input:radio[name=traveltype]:checked').val();

  // Check for 180 days limit only when maximum number of days is below
  // or equal to 180.
  if (
    (type == 'single' || type == 'single_individual_benefit') &&
    maxDays <= 180 &&
    amt > 180
  ) {
    bootbox.alert(MORE_180_DAYS);
    return false;
  }
  if (type == 'overseas_study' && amt > 744) {
    bootbox.alert(MORE_744_DAYS);
    return false;
  }

  return true;
}

function setMaxDate(type) {
  type = typeof type !== 'undefined' ? type : '';

  var getTravelType = $('input:radio[name=traveltype]:checked').val();
  var getDestination = $('input:radio[name=destination]:checked').val();
  var arrDataMaxDay = JSON.parse($('#jsonMaxDay').val());

  var maxDay = arrDataMaxDay[getTravelType][getDestination] - 1; // - 1 because count form date is 1

  switch (getTravelType) {
    case 'single':
      var goId = 'go';
      var fromId = 'back';
      break;
    case 'annual':
      var goId = 'go';
      var fromId = 'back';
      break;
    case 'overseas_study':
      var goId = 'goOs';
      var fromId = 'backOs';
      break;
    case 'business':
      var goId = 'go';
      var fromId = 'back';
      break;
    case 'single_individual_benefit':
      var goId = 'go';
      var fromId = 'back';
      break;
    default:
      var goId = 'go';
      var fromId = 'back';
      break;
  }

  if (getTravelType != 'annual') {
    // if(type == 'cDateSt'){
    var getDateFrom = $('#' + goId)
      .val()
      .split('/');
    var setMaxDay = new Date(
      getDateFrom[2],
      getDateFrom[1] - 1,
      getDateFrom[0]
    );

    setMaxDay.setDate(setMaxDay.getDate() + maxDay);
    $('#' + fromId).datepicker('setEndDate', setMaxDay);

    //    console.log(maxDay + ' Day');
    //    console.log(setMaxDay + ' Day ++');
    //}
  }
}

function checkSumTraveller(inpId) {
  var maxTraveller = 10;
  var sumNum = 0;
  var chk = false;
  var msg = '';

  $('.inpNumTraveller').each(function () {
    var getNum = $(this).val();
    sumNum += parseInt(getNum);
  });

  if (sumNum > maxTraveller) {
    msg = NUM_TRAVELLER_NOT_EXCEED + maxTraveller; //'Number of traveller(s) must not exceed ' + maxTraveller;
    $('#' + inpId).val(min);
    /*$('.inpNumTraveller').each(function () {
            var min = parseInt($(this).attr('min'));
            $(this).val(min);
        });*/
  } else {
    chk = true;
  }

  return [chk, msg];
}
